import {
    language,
} from '../common/language'
$.expr[":"].like = $.expr.createPseudo(function(pattern) {
    return function(element) {
        return $(element).text().toUpperCase().indexOf(pattern.toUpperCase()) >= 0;
    };
});

$('#search').on('input', function() {
    const pattern = $(this).val();
    if (pattern.length) {
        $('.tour-card').hide();
        $('.tour-card-header:like(' + pattern + ')').parents('.tour-card').show();
    } else {
        $('.tour-card').show();
    }
});

$('#new-tour').on('show.bs.modal', function() {
    $('#new-tour').find('#name').val('');
    $('#new-tour').find('.text-danger').detach();
});

$('.btn-set-tour').on('click', function() {
    const tour = {
        name: $('#new-tour').find('#name').val(),
    };
    $.post('/tour/new', tour, (result) => {
        if (result.status === false) {
            $('#new-tour').find('.text-danger').detach();
            $('#new-tour').find('.modal-body').prepend('<p class="text-danger">' + result.error + '</p>');
        } else {
            $('#new-tour').find('.text-danger').detach();
            $('#object').find('option[value="' + result.id + '"]').detach();
            $('#alerts').find('.col-12').detach();
            $('#alerts').append(
                '<div class="col-12"><div class="alert alert-success">Es wurde ein neuer Rundgang angelegt</div></div>',
            );
            $('#new-tour').modal('hide');
            $('#cards').append(result.output);
            setTimeout(() => $('#alerts').find('.col-12').fadeOut(), 1000);
        }
    });
});

$('body').on('change', '.switch-active', (e) => {
    const switchElement = $(e.currentTarget);
    const tour_id = switchElement.data('tour');
    const active = switchElement.is(':checked');

    const object = [
        tour_id,
        active,
    ];
    $.post('/tour/change_active', {object: object}, (result) => {
        if (result.status === false) {
            switchElement.prop('checked', !switchElement.prop('checked'));
            $('#alerts').find('.col-12').detach();
            $('#alerts').append(
                `<div class="col-12"><div class="alert alert-danger">${result.error}</div></div>`,
            );
            setTimeout(() => $('#alerts').find('.col-12').fadeOut(), 2000);
        } else {
            $('#alerts').find('.col-12').detach();
            if (result.active) {
                $('#img-' + tour_id).removeClass('dimmed');
            } else {
                $('#img-' + tour_id).addClass('dimmed');
            }
            $('#check-active-' + tour_id).prop('checked', result.active);
            if (language === 'de') {
                $('#label-active-' + tour_id).text(result.active ? 'Aktiv' : 'Inaktiv');
            } else {
                $('#label-active-' + tour_id).text(result.active ? 'Active' : 'Inactive');
            }
        }
    });
});

$('.btn-delete-tour').on('click', function() {
    const id = $('#modalDeleteTour').data('id');
    //const name = $('#modalDeleteTour').data('name');
    $.post('/tour/delete', {id: id}, function(result) {
        if (result.response.status === true) {
            $('#modalDeleteTour').modal('hide');
            $('#card-' + result.response.id).closest('.tour-card').detach();
            $('#alerts').find('.col-12').detach();
            $('#alerts').append(
                '<div class="col-12"><div class="alert alert-success">Der Rundgang wurde gelöscht.</div></div>',
            );
            setTimeout(() => $('#alerts').find('.col-12').fadeOut(), 1000);
        } else {
            $('#alerts').find('.col-12').detach();
            $('#alerts').append(
                '<div class="col-12"><div class="alert alert-danger">Es ist ein Fehler aufgetreten.</div></div>',
            );
            setTimeout(() => $('#alerts').find('.col-12').fadeOut(), 1000);
        }
    });
});

$('.btn-break').on('click', function() {
    window.history.back();
});

$('body').on('click', '.delete-button', function() {
    $('#modalDeleteTour').data('id', $(this).data('id'));
    $('#modalDeleteTour').data('name', $(this).data('name'));
    $('#modalDeleteTour').find('#tour_name').text($(this).data('name'));
    $('#modalDeleteTour').modal('show');
});
