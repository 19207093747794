import Dropzone from 'dropzone';
import * as markerjs2 from 'markerjs2';

const job_image_managers = new WeakMap();

function attachJobImageFileManagers(pattern) {
    $(pattern || '.file-manager').each(function() {
        const element = $(this).get()[0];
        let manager = job_image_managers.get(element);
        if (!manager) {
            manager = {
                url: $(this).data('url'),
                dropzone: null,
            };
            job_image_managers.set(element, manager);

            const startEditor = function(e) {
                e.preventDefault();
                e.stopPropagation();

                const dz_image = $(e.target).closest('.dz-preview');
                const url = dz_image.data('url');

                const img = $('<img style="position:absolute; left: -999em" />')
                    .attr('src', url + '?target=preview');
                    //.attr('src', manager.url + '?target=preview');
                img.appendTo('body');
                img.on('load', function() {
                    $.ajax({
                        type: 'GET',
                        url: url + '?target=annotations',
                        //url: manager.url + '?target=annotations',
                        success: function(result) {
                            const markerArea = new markerjs2.MarkerArea(img[0]);
                            markerArea.uiStyleSettings.zIndex = 2000;
                            markerArea.settings.displayMode = 'popup';
                            markerArea.addCloseEventListener(function() {
                                setTimeout(function() {
                                    img.remove();
                                }, 1000);
                            });
                            markerArea.addEventListener('render', function(evt) {
                                $.ajax({
                                    type: 'POST',
                                    url: url + '?target=annotations',
                                    //url: manager.url + '?target=annotations',
                                    contentType: 'application/json',
                                    data: JSON.stringify(evt.state),
                                    success: function() {
                                        markerArea.close();
                                        setTimeout(function() {
                                            img.remove();
                                        }, 1000);
                                    },
                                });
                            });
                            markerArea.show();
                            if (result) {
                                markerArea.restoreState(result);
                            }
                        },
                    });
                });
            };

            $.ajax({
                type: 'GET',
                url: manager.url + '?target=meta',
                success: function(response) {
                    if (response && response.mode === 'annotate') {
                        //const editButton = $('' +
                        //    '<button class="btn btn-primary w-100 py-4">' +
                        //        '<i class="fas fa-edit"></i> ' + (response.annotationLabel || 'Edit') +
                        //    '</button>',
                        //);
                        //$(element).append(editButton).on('click', startEditor);
                    } else if (response && response.files && response.config) {
                        const config = response.config;
                        const dropzone = manager.dropzone = new Dropzone(element, Object.assign(
                            {
                                url: manager.url,
                                maxFiles: 50,
                                parallelUploads: 50,
                                acceptedFiles: '.png, .jpg, .dwg, .ai, .jpeg, .pdf, .bmp',
                                createImageThumbnails: true,
                                uploadMultiple: false,
                                thumbnailWidth: 350,
                                thumbnailHeight: 350,
                                thumbnailMethod: 'contain',
                                previewsContainer: $('.image-preview')[0],
                            },
                            response.config,
                            {
                                init: function() {
                                    function showError(error) {
                                        const text = error.replace('\n', '<br>');
                                        $('<div class="alert alert-danger"><p>' + text + '</p></div>')
                                            .appendTo(element).fadeOut(4000);
                                    }

                                    for (let i = 0; i < response.files.length; i++) {
                                        const file = response.files[i];
                                        const df = {
                                            name: file.filename,
                                            size: file.size,
                                            file: file,
                                            url: file.url,
                                        };
                                        this.options.maxFiles -= 1;
                                        this.displayExistingFile(
                                            df,
                                            df.url,
                                        );
                                    }

                                    this.on('maxfilesexceeded', function(dropzoneFile) {
                                        this.removeFile(dropzoneFile);
                                        showError(
                                            config.locale === 'de' ?
                                                (
                                                    "Es ist nur eine Datei pro Teilauftrag erlaubt.\n" +
                                                    "Sie müssen die bisherige Datei zuerst entfernen, " +
                                                    "um eine neue hochzuladen."
                                                ) :
                                                (
                                                    "There's only one file allowed per job.\n" +
                                                    "Before uploading a new file you have to remove the current one."
                                                ),
                                        );
                                    });

                                    const decorateFile = function(dropzoneFile) {
                                        if (dropzoneFile.file === undefined) {
                                            return;
                                        }
                                        const removeButton = $('' +
                                            '<button class="btn btn-danger dropzone-remove-file">' +
                                            '<i class="fa fa-trash"></i>' +
                                            '</button>',
                                        );

                                        const extension = (dropzoneFile.file.filename || '').toLowerCase().split('.').pop();
                                        if (extension !== 'png' && extension !== 'jpg' && extension !== 'jpeg') {
                                            $(dropzoneFile.previewTemplate)
                                                .find('.dz-image')
                                                .attr('data-url', dropzoneFile.file.url)
                                                .css('display', 'flex')
                                                .css('justify-content', 'center')
                                                .css('align-items', 'center')
                                                .addClass('bg-success')
                                                .html(
                                                    $('<span>')
                                                        .css('font-weight', 'bold')
                                                        .css('color', 'white')
                                                        .text(extension.toUpperCase()),
                                                );
                                        }

                                        $(dropzoneFile.previewTemplate).append(removeButton);
                                        $(removeButton).on('click', function(e) {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            $.ajax({
                                                type: 'DELETE',
                                                url: dropzoneFile.file.url,
                                                success: function() {
                                                    dropzone.removeFile(dropzoneFile);
                                                    dropzone.options.maxFiles += 1;
                                                },
                                            });
                                        });

                                        const description = $('' +
                                            '<input type="text" class="form-control" name="message[' + dropzoneFile.file.id + ']" placeholder="Was soll entfernt werden?" value="' + dropzoneFile.file.message + '">',
                                        );
                                        $(description).on('click', function(e) {
                                            e.stopPropagation();
                                        });
                                        if ($('#option_image_object_removal').prop('checked') === false) {
                                            $(description).hide();
                                        }
                                        $(dropzoneFile.previewTemplate).append(description);

                                        const editButton = $('' +
                                            '<button class="btn btn-success dropzone-edit-file">' +
                                            '<i class="fas fa-edit"></i>' +
                                            '</button>',
                                        );
                                        //$(dropzoneFile.previewTemplate).append(editButton);
                                        $(dropzoneFile.previewTemplate)
                                                .attr('data-url', dropzoneFile.file.url);
                                        $(editButton).on('click', startEditor);

                                        $(dropzoneFile.previewTemplate).on('click', function(e) {
                                            //if (e.originalEvent.explicitOriginalTarget !== e.target) {
                                            //    return;
                                            //}
                                            e.preventDefault();
                                            window.location = dropzoneFile.file.url;
                                        });
                                    };

                                    this.on('success', function(dropzoneFile, response) {
                                        if (response.status === 'error') {
                                            showError(response.error);
                                            dropzone.removeFile(dropzoneFile);
                                        } else if (response.action === 'reload') {
                                            window.location.reload();
                                        } else {
                                            $(element).parent().find('.alert.alert-danger').remove();
                                            //dropzoneFile.file = response.file;
                                            dropzoneFile.id = response.file.id;
                                            dropzoneFile.name = response.file.filename;
                                            dropzoneFile.size = response.file.size;
                                            dropzoneFile.file = response.file;
                                            dropzoneFile.url = response.file.url;
                                            decorateFile(dropzoneFile);
                                        }

                                    });

                                    this.on('thumbnail', decorateFile);
                                },
                            }));
                        $(element).addClass('dropzone');
                        $(element).append(
                            '<div class="dz-message" data-dz-message>' +
                                '<span>' +
                                    (
                                        config.locale === 'de' ?
                                            'Dateien in den Rahmen ziehen oder hineinklicken, um Dateien zu durchsuchen' :
                                            'Drag files into the border or click to open a file browser'
                                    ) +
                                '</span>' +
                            '</div>',
                        );
                    }
                },
            });
        }
    });
}

window.attachJobImageFileManagers = attachJobImageFileManagers;
