import {
    datatablesDefaultOptions,
} from './dt';

const renderOptions = function(data, type, row) {
    return '<a class="btn downloadInvoice" type="button" href="' + row.link + '"><i class="fas fa-search text-primary" ></i></button>';
};

const renderId = function(data, type, row) {
    return '<a href="' + row.link + '">' + row.job_id;
};

const renderCorrectionId = function(data, type, row) {
    return '<a href="' + row.detail_url + '">' + row.job_id;
};

const renderCorrectionOptions = function(data, type, row) {
    return '<a class="btn" type="button" href="' + row.detail_url + '"><i class="fas fa-search text-primary" ></i></button>';
};

$('#table-jobhistory').DataTable($.extend({}, datatablesDefaultOptions, {
    rowReorder: {
        update: true,
    },
    columnDefs: [

        {
            targets: 0,
            render: renderId,
        },

        {
            targets: 6,
            render: renderOptions,
        },

    ],
}));

$('#table-jobs').DataTable($.extend({}, datatablesDefaultOptions, {
    rowReorder: {
        update: true,
    },
    columnDefs: [

        {
            targets: 0,
            render: renderId,
        },

        {
            targets: 6,
            render: renderOptions,
        },

    ],
}));

$('#table-orders').DataTable($.extend({}, datatablesDefaultOptions, {
    rowReorder: {
        update: true,
    },
    columnDefs: [

        {
            targets: 0,
            render: renderCorrectionId,
        },

        {
            targets: 4,
            render: renderCorrectionOptions,
        },

    ],
}));
