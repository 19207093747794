import Dropzone from 'dropzone';
import * as markerjs2 from 'markerjs2';

const managers = new WeakMap();

function attachFileManagers(pattern) {
    $(pattern || '.file-manager').each(function() {
        const element = $(this).get()[0];
        const values = (Object.values($(this)));
        let manager = managers.get(element);
        if (!manager) {
            manager = {
                url: $(this).data('url'),
                dropzone: null,
            };
            managers.set(element, manager);

            const startEditor = function(e) {
                e.preventDefault();
                e.stopPropagation();
                $('.overlay-wrapper').show();

                const img = $('<img style="position:absolute; left: -999em" />')
                    .attr('src', manager.url + '?target=preview');
                img.appendTo('body');
                img.on('load', function() {
                    $.ajax({
                        type: 'GET',
                        url: manager.url + '?target=annotations',
                        complete: function() {
                            $('.overlay-wrapper').hide();
                        },
                        success: function(result) {
                            const markerArea = new markerjs2.MarkerArea(img[0]);
                            markerArea.uiStyleSettings.zIndex = 2000;
                            markerArea.settings.displayMode = 'popup';
                            markerArea.addCloseEventListener(function() {
                                setTimeout(function() {
                                    img.remove();
                                }, 1000);
                            });
                            markerArea.addEventListener('render', function(evt) {
                                $.ajax({
                                    type: 'POST',
                                    url: manager.url + '?target=annotations',
                                    contentType: 'application/json',
                                    data: JSON.stringify(evt.state),
                                    success: function() {
                                        markerArea.close();
                                        setTimeout(function() {
                                            img.remove();
                                        }, 1000);
                                    },
                                });
                            });
                            markerArea.show();
                            if (result) {
                                markerArea.restoreState(result);
                            }
                            $('.overlay-wrapper').hide();
                        },
                    });
                });
            };

            $.ajax({
                type: 'GET',
                url: manager.url + '?target=meta',
                success: function(response) {
                    if (response && response.mode === 'annotate') {
                        const editButton = $('' +
                            '<button class="btn btn-primary w-100 py-4">' +
                                '<i class="fas fa-edit"></i> ' + (response.annotationLabel || 'Edit') +
                            '</button>' + '<span class="small text-secondary">' + (response.annotationWarning || '') + '</span>',
                        );
                        $(element).append(editButton).on('click', startEditor);
                    } else if (response && response.files && response.config) {
                        const config = response.config;
                        const dropzone = manager.dropzone = new Dropzone(element, Object.assign(
                            {
                                url: manager.url,
                                maxFiles: 50,
                                parallelUploads: 50,
                                acceptedFiles: '.png, .jpg, .dwg, .ai, .jpeg, .pdf, .bmp',
                                createImageThumbnails: true,
                                uploadMultiple: false,
                                autoQueue: false,
                                autoProcessQueue: true,
                            },
                            response.config,
                            {
                                init: function() {
                                    this.on('addedfile', function(file) {
                                        if (file.status !== 'added') {
                                            return;
                                        }

                                        const that = this;
                                        const type = file.name.split('.').pop();
                                        if ((type !== 'pdf') || (values[0]['id'] === 'attachments')) {
                                            setTimeout(function() {
                                                that.enqueueFile(file);
                                            }, 0);
                                            return;
                                        }
                                        const modal = $('#modalAutoSplit');
                                        modal.data('file', file);
                                        modal.modal('show');
                                        modal.find('.btn-split-pdf').off('click').on('click', function(ev) {
                                            ev.stopPropagation();
                                            ev.preventDefault();
                                            modal.modal('hide');
                                            file.split = true;
                                            that.enqueueFile(file);
                                        });
                                        modal.find('.btn-no-split').off('click').on('click', function(ev) {
                                            ev.stopPropagation();
                                            ev.preventDefault();
                                            modal.modal('hide');
                                            that.enqueueFile(file);
                                        });
                                    });
                                    this.on('sending', function(file, xhr, formData) {
                                        formData.append("split", file.split === true ? true : false);
                                    });
                                    function showError(error) {
                                        const text = error.replace('\n', '<br>');
                                        $('<div class="alert alert-danger"><p>' + text + '</p></div>')
                                            .appendTo(element).fadeOut(4000);
                                    }

                                    for (let i = 0; i < response.files.length; i++) {
                                        const file = response.files[i];
                                        const dropzoneFile = {
                                            name: file.filename,
                                            size: file.size,
                                            file: file,
                                        };
                                        this.options.maxFiles -= 1;
                                        this.displayExistingFile(
                                            dropzoneFile,
                                            file.url,
                                        );
                                    }

                                    this.on('maxfilesexceeded', function(dropzoneFile) {
                                        this.removeFile(dropzoneFile);
                                        showError(
                                            config.locale === 'de' ?
                                                (
                                                    "Es ist nur eine Datei pro Teilauftrag erlaubt.\n" +
                                                    "Sie müssen die bisherige Datei zuerst entfernen, " +
                                                    "um eine neue hochzuladen."
                                                ) :
                                                (
                                                    "There's only one file allowed per job.\n" +
                                                    "Before uploading a new file you have to remove the current one."
                                                ),
                                        );
                                    });

                                    const decorateFile = function(dropzoneFile) {
                                        const extension = (dropzoneFile.file.filename || '').toLowerCase().split('.').pop();
                                        if (extension !== 'png' && extension !== 'jpg' && extension !== 'jpeg') {
                                            $(dropzoneFile.previewTemplate).find('.dz-image')
                                                .css('display', 'flex')
                                                .css('justify-content', 'center')
                                                .css('align-items', 'center')
                                                .addClass('bg-success')
                                                .html(
                                                    $('<span>')
                                                        .css('font-weight', 'bold')
                                                        .css('color', 'white')
                                                        .text(extension.toUpperCase()),
                                                );
                                        }

                                        const removeButton = $('' +
                                            '<button class="btn btn-danger dropzone-remove-file">' +
                                            '<i class="fa fa-trash"></i>' +
                                            '</button>',
                                        );
                                        $(removeButton).on('click', function(e) {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            $.ajax({
                                                type: 'DELETE',
                                                url: dropzoneFile.file.url,
                                                success: function() {
                                                    dropzone.removeFile(dropzoneFile);
                                                    dropzone.options.maxFiles += 1;
                                                },
                                            });
                                        });

                                        $(dropzoneFile.previewTemplate).append(removeButton);

                                        if (response.annotationLabel) {
                                            const editButton = $('' +
                                                '<button class="btn btn-primary dropzone-edit-file" data-toggle="tooltip" data-title="' + (response.annotationLabel) + '">' +
                                                    '<i class="fas fa-edit"></i> ' +
                                                '</button>',
                                            );
                                            $(dropzoneFile.previewTemplate).append(editButton);
                                            $(editButton).on('click', startEditor);
                                        }

                                        $(dropzoneFile.previewTemplate).on('click', function(e) {
                                            e.preventDefault();
                                            window.location = dropzoneFile.file.url;
                                        });
                                    };

                                    this.on('success', function(dropzoneFile, response) {
                                        if (response.status === 'error') {
                                            showError(response.error);
                                            dropzone.removeFile(dropzoneFile);
                                        } else if (response.action === 'reload') {
                                            window.location.reload();
                                        } else {
                                            $(element).parent().find('.alert.alert-danger').remove();
                                            dropzoneFile.file = response.file;

                                            decorateFile(dropzoneFile);
                                        }

                                    });
                                    this.on('thumbnail', function(file, dataUrl) {
                                        file.file = {
                                            filename: file.name,
                                            url: file.dataURL,
                                        };
                                        decorateFile(file, dataUrl);
                                    });
                                },
                            }));
                        $(element).addClass('dropzone');
                        $(element).append(
                            '<div class="dz-message" data-dz-message>' +
                                '<span>' +
                                    (
                                        config.locale === 'de' ?
                                            'Dateien in den Rahmen ziehen oder hineinklicken, um Dateien zu durchsuchen' :
                                            'Drag files into the border or click to open a file browser'
                                    ) +
                                '</span>' +
                            '</div>',
                        );
                    }
                },
            });
        }
    });
}

window.attachFileManagers = attachFileManagers;
