const switchElem = $('#register_type');

switchElem.on('change', function() {
    handleSwitchEffects();
});

handleSwitchEffects();

function handleSwitchEffects() {
    if (switchElem.prop('checked')) {
        $('.company-label').removeClass('active');
        $('.private-label').addClass('active');
        $('.private-form').removeClass('d-none');
        $('.company-form').addClass('d-none');
        $('.company-form input').prop('required', false);
        $('.private-form input').prop('required', true);
    } else {
        $('.company-label').addClass('active');
        $('.private-label').removeClass('active');
        $('.private-form').addClass('d-none');
        $('.company-form').removeClass('d-none');
        $('.company-form input[name!="vateu"]').prop('required', true);
        $('.private-form input').prop('required', false);
    }
}

$('#submitRegisterForm').on('click', function(e) {
    e.preventDefault();
    const switchElem = $('#register_type');
    $('.has-error').removeClass('has-error');
    $('.error-msg').remove();
    if (switchElem.prop('checked')) {
        const data = {
            register_type: 'private',
            fname: $('.private-form [name="fname"]').val(),
            lname: $('.private-form [name="lname"]').val(),
            customer_title: $('.private-form [name="customer_title"]').val(),
            email: $('.private-form [name="email"]').val(),
            street: $('.private-form [name="street"]').val(),
            phone: $('.private-form [name="phone"]').val(),
            streetnr: $('.private-form [name="streetnr"]').val(),
            city: $('.private-form [name="city"]').val(),
            zip: $('.private-form [name="zip"]').val(),
            country: $('.private-form [name="country"]').val(),
            language: $('.private-form [name="language"]').val(),
            agb: $('.private-form [name="agb"]').prop('checked'),
            revocation: $('.private-form [name="revocation"]').prop('checked'),
        };
        $.post('/registrierung', data, function(result) {
            if (result.status === 'ok') {
                location.href = "/registrierung/bestaetigung";
            }
            $.each(result.errors, function(idx, val) {
                const errorElem = $('.private-form [name="' + idx + '"]');
                errorElem.addClass('has-error');
                errorElem.closest('.form-group').append('<small class="error-msg">' + val + '</small>');
            });
        });
    } else {
        const data = {
            register_type: 'company',
            fname: $('.company-form [name="fname"]').val(),
            lname: $('.company-form [name="lname"]').val(),
            company: $('.company-form [name="company"]').val(),
            customer_title: $('.company-form [name="customer_title"]').val(),
            email: $('.company-form [name="email"]').val(),
            street: $('.company-form [name="street"]').val(),
            phone: $('.company-form [name="phone"]').val(),
            streetnr: $('.company-form [name="streetnr"]').val(),
            city: $('.company-form [name="city"]').val(),
            zip: $('.company-form [name="zip"]').val(),
            country: $('.company-form [name="country"]').val(),
            language: $('.company-form [name="language"]').val(),
            vateu: $('.company-form [name="vateu"]').val(),
            agb: $('.company-form [name="agb"]').prop('checked'),
        };
        $.post('/registrierung', data, function(result) {
            if (result.status === 'ok') {
                location.href = "/registrierung/bestaetigung";
            }
            $.each(result.errors, function(idx, val) {
                const errorElem = $('.company-form [name="' + idx + '"]');
                errorElem.addClass('has-error');
                errorElem.closest('.form-group').append('<small class="error-msg">' + val + '</small>');
            });
        });
    }
});
