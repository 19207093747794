import {
    datatablesDefaultOptions,
} from './dt';
import {
    language,
} from '../../common/language.js';

const renderRowControls = function(data, type, row) {
    return '<a class="btn downloadInvoice" type="button" href="' + row.offer_pdf_url + '"><i class="fa fa-download"></i></button>';
};

const renderStatusBadge = function(data, type, row) {
    if (language === 'de') {
        if (row.storno) {
            return '<span class="badge badge-warning">Storniert</span>';
        }
        if (data === 'offen') {
            return '<span class="badge badge-warning">Offen</span><a href="#" class="accept-offer" data-id="' + row.DT_RowId + '" data-offer-number="' + row.offer_number + '"><i class="fa fa-check"></i></a>' +
                '<a href="#" class="reject-offer text-danger" data-id="' + row.DT_RowId + '" data-offer-number="' + row.offer_number + '"><i class="fa fa-times"></i></a>';
        } else if (data === 'angenommen') {
            return '<span class="badge badge-success">Angenommen</span>';
        } else {
            return '<span class="badge badge-danger">Abgelehnt</span>';
        }
    } else {
        if (row.storno) {
            return '<span class="badge badge-warning">canceled</span>';
        }
        if (data === 'offen') {
            return '<span class="badge badge-warning">Offen</span><a href="#" class="accept-offer" data-id="' + row.DT_RowId + '" data-offer-number="' + row.offer_number + '"><i class="fa fa-check"></i></a>' +
                '<a href="#" class="reject-offer text-danger" data-id="' + row.DT_RowId + '" data-offer-number="' + row.offer_number + '"><i class="fa fa-times"></i></a>';
        } else if (data === 'angenommen') {
            return '<span class="badge badge-success">accepted</span>';
        } else {
            return '<span class="badge badge-danger">declined</span>';
        }
    }
};

$('#table-offers').DataTable($.extend({}, datatablesDefaultOptions, {
    drawCallback: function() {
        $('.accept-offer').on('click', function() {
            const $modal = $('#modalAcceptOffer');
            $modal.find('.offer-number').text($(this).data('offer-number'));
            $modal.data({
                id: $(this).data('id'),
            }).modal('show');
        });
        $('.reject-offer').on('click', function() {
            const $modal = $('#modalRejectOffer');
            $modal.find('.offer-number').text($(this).data('offer-number'));
            $modal.data({
                id: $(this).data('id'),
            }).modal('show');
        });
    },
    rowReorder: {
        update: true,
    },
    columnDefs: [
        {
            targets: 3,
            render: renderStatusBadge,
        },
        {
            targets: 4,
            render: renderRowControls,
        },
    ],
}));

$('.btn-mark-accepted').click(function() {
    const $modal = $('#modalAcceptOffer');
    $.post('/angebote/accepted', {id: $modal.data('id')}, function() {
        $('#table-offers').DataTable().ajax.reload(null, false);
        $modal.modal('hide');
    });
});

$('.btn-mark-rejected').click(function() {
    const $modal = $('#modalRejectOffer');
    $.post('/angebote/rejected', {id: $modal.data('id')}, function() {
        $('#table-offers').DataTable().ajax.reload(null, false);
        $modal.modal('hide');
    });
});
