import '../../node_modules/mithril/mithril.min.js';
import '../../node_modules/markerjs2/markerjs2.js';
import '../common/files.js';
import '../common/minilist.js';
import '../common/keep-alive.js';
import '../common/textarea-autosize.js';
import '../common/multi-dropzone.js';
import '../common/string-transform.js';
import {
    setLanguage,
} from '../common/language.js';
import './tables/dt.js';
import './job-image-files.js';
import './settings.js';
import './register.js';
import './tour.js';
import './tables/history.js';
import './tables/tour-contacts.js';
import './tables/invoices.js';
import './tables/offers.js';
import './tables/credits.js';
import './tables/provisions.js';
import './tables/jobs.js';
import '../common/friendly-captcha'
import 'friendly-challenge/widget';
setLanguage(document.all[0].lang || 'de');

$(document).on('click', '.order-submit', function(ev) {
    ev.preventDefault();
    const $overlay = $('#overlay');
    $overlay.show();
    let form = $('#order')[0];
    if (form === undefined) {
        form = $('#summary')[0];
    }
    const formData = new FormData(form);
    formData.append('next-step', true);
    $.ajax({
        method: 'POST',
        url: window.location,
        data: formData,
        processData: false,
        contentType: false,
        enctype: "multipart/form-data",
        success: function(response) {
            window.location.assign(response.redirect_url);
        },
    });
});

$(function() {
    $(function() {
        $('[data-toggle="tooltip"]').tooltip();
    });

    $('.user-menu.dropdown').on('show.bs.dropdown', function() {
        $(this).find('.dropdown-menu').first().stop(true, true).fadeIn();
    });

    $('.user-menu.dropdown').on('hide.bs.dropdown', function() {
        $(this).find('.dropdown-menu').first().stop(true, true).fadeOut();
    });

    const chooseLogo = function() {
        if ($('.sidebar').hasClass('imageyard')) {
            $('.navbar-brand-icon')
                .removeClass('floorplan-icon')
                .removeClass('tour-icon')
                .addClass('imageyard-icon');
            $('.navbar-brand-icon img').attr('src', '/app/images/logo/bilderwerft-white.png');
        } else if ($('.sidebar').hasClass('tour')) {
            $('.navbar-brand-icon')
                .removeClass('floorplan-icon')
                .removeClass('imageyard-icon')
                .addClass('tour-icon');
            $('.navbar-brand-icon img').attr('src', '/app/images/logo/grundriss-schmiede-360-white.png');
        } else if ($('.sidebar').hasClass('main')) {
            $('.navbar-brand-icon')
                .removeClass('tour-icon')
                .removeClass('imageyard-icon')
                .addClass('floorplan-icon');
            $('.navbar-brand-icon img').attr('src', '/app/images/logo/grundriss-schmiede-logo-white.png');
        }
    };

    $('.language-item').on('click', function() {
        const lang = $(this).prop('name');
        $.ajax({
            url: '/change-lang',
            method: 'POST',
            data: {
                language: lang,
            },
            success: function(response) {
                if (response === undefined) {
                    location.reload();
                }
            },
        });
    });


    const chooseNavi = function() {
        //$('.sidebar .tab-pane').removeClass('active');
        const newClass = $('#headNav a.active').attr('id');
        $('#headNav a.active').tab('show');
        if (newClass === 'imageyard') {
            $('.sidebar')
                .removeClass('main')
                .removeClass('tour')
                .addClass('imageyard');
        } else if (newClass === 'tour') {
            $('.sidebar')
                .removeClass('main')
                .removeClass('imageyard')
                .addClass('tour');
        } else {
            $('.sidebar')
                .removeClass('tour')
                .removeClass('imageyard')
                .addClass('main');
        }
    };

    $('#headNav a').on('click', function() {
        //e.preventDefault();
        document.location = $(this).attr('href');
        $(this).tab('show');
        const newClass = $(this).attr('id');
        if (newClass === 'imageyard') {
            $('.sidebar')
                .removeClass('main')
                .removeClass('tour')
                .addClass('imageyard');
        } else if (newClass === 'tour') {
            $('.sidebar')
                .removeClass('main')
                .removeClass('imageyard')
                .addClass('tour');
        } else {
            $('.sidebar')
                .removeClass('tour')
                .removeClass('imageyard')
                .addClass('main');
        }
        chooseLogo();
    });

    window.chooseLogo = chooseLogo;
    window.chooseNavi = chooseNavi;

    chooseLogo();
    chooseNavi();

    //mobile menu
    const toggleMobileMenu = function(e) {
        const $mobileMenu = $('#navbarCollapse');
        const $body = $('body');
        if (e) {
            e.preventDefault();
        }

        if ($mobileMenu.css('display') === 'none') {
            $mobileMenu.css('display', 'block');
        }
        window.setTimeout(function() {
            if ($mobileMenu.hasClass('open')) {
                $body.css('overflow', 'visible');
                $mobileMenu.find('.wrapper').removeClass('show');
                window.setTimeout(function() {
                    $mobileMenu.css('display', 'none');
                }, 500);
            } else {
                $body.css('overflow', 'hidden');
                $mobileMenu.find('.wrapper:not(.sub)').addClass('show');
            }
            $mobileMenu.toggleClass('open');
        }, 0);
    };
    $('.navbar-toggler, #navbarCollapse > .menu-overlay').click(toggleMobileMenu);

    $(window).resize(function() {
        const $mobileMenu = $('#navbarCollapse');
        const $body = $('body');
        if ($('#navbarCollapse').hasClass('open')) {
            $body.css('overflow', 'visible');
            $mobileMenu.find('.wrapper').removeClass('show');
            window.setTimeout(function() {
                $mobileMenu.css('display', 'none');
            }, 500);
        }
    });

    const subMobileMenu = function(e) {
        if (e) {
            e.preventDefault();
        }
        const $this = $(this);
        const $menu = $this.closest('.wrapper');

        const targetCat = $this.data('cat');
        let level = $menu.data('level');
        if ($this.hasClass('back')) {
            level -= 1;
        } else {
            level += 1;
        }

        const $submenu = $this.closest('#navbarCollapse').find('[data-level="' + level + '"][data-cat="' + targetCat + '"]');
        if ($submenu.length > 0) {
            $menu.removeClass('show');
            $submenu.addClass('show');
        }
    };

    $('#navbarCollapse .item[data-cat]').click(subMobileMenu);
});
