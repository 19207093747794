import {WidgetInstance} from "friendly-challenge/index";

$(document).ready(() => {
    const element = $('#captcha');
    const options = {
        doneCallback: doneCallback,
        sitekey: fc_sitekey,
    };
    let widget = undefined;
    if (Object.keys(element).length !== 0) {
        widget = new WidgetInstance(element[0], options);
        const button = $('.captcha-btn');
        const tag = $(button).prop("tagName");
        if (tag === 'BUTTON') {
            $(button[0]).prop('disabled', true);
        } else if (tag === 'A') {
            $(button[0]).addClass('disabled');
        }
    }
    function doneCallback(solution) {
        const button = $('.captcha-btn');
        const tag = $(button).prop("tagName");
        if (tag === 'BUTTON') {
            $(button[0]).removeAttr('disabled');
        } else if (tag === 'A') {
            $(button[0]).removeClass('disabled');
        }
    }
});
