import {
    datatablesDefaultOptions,
} from './dt';
import {
    language,
} from '../../common/language.js';

const renderStatusCell = function(data) {
    let $row = '';
    if (language === 'de') {
        switch (data) {
            case 'Ausstehend':
                $row = '<span class="badge badge-pill badge-warning">Ausstehend</span>';
                break;
            case 'Ausgezahlt':
                $row = '<span class="badge badge-pill badge-success">Ausgezahlt</span>';
                break;
            case 'Storniert':
                $row = '<span class="badge badge-pill badge-danger">Storniert</span>';
                break;
            case 'Bestätigt':
                $row = '<span class="badge badge-pill badge-info">Bestätigt</span>';
                break;
        }
    } else {
        switch (data) {
            case 'Ausstehend':
                $row = '<span class="badge badge-pill badge-warning">pending</span>';
                break;
            case 'Ausgezahlt':
                $row = '<span class="badge badge-pill badge-success">paid out</span>';
                break;
            case 'Storniert':
                $row = '<span class="badge badge-pill badge-danger">canceled</span>';
                break;
            case 'Bestätigt':
                $row = '<span class="badge badge-pill badge-info">confirmed</span>';
                break;
        }
    }
    return $row;
};

$('#table-provisions').DataTable($.extend({}, datatablesDefaultOptions, {
    rowReorder: {
        update: true,
    },
    columnDefs: [
        {
            targets: 3,
            render: renderStatusCell,
        },
    ],
}));
