import Dropzone from 'dropzone';

const replacement = 'data:image/svg+xml;charset=UTF-8,<svg width="100" height="100" viewBox="0 0 16 16" class="bi bi-file-earmark-check-fill" fill="green" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M2 2a2 2 0 0 1 2-2h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm7.5 1.5v-2l3 3h-2a1 1 0 0 1-1-1zm1.354 4.354a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"/></svg>';

Dropzone.autoDiscover = false;

$(function() {
    $('.multi-dropzone').each(function() {
        const baseUrl = $(this).data('url');
        const element = this;
        $.ajax({
            type: 'GET',
            url: baseUrl,
            success: function(result) {
                const files = result.files;
                const dropzone = new Dropzone(element, {
                    maxFiles: 50,
                    parallelUploads: 50,
                    acceptedFiles: '.png, .jpg, .dwg, .ai, .jpeg, .pdf, .bmp',
                    createImageThumbnails: true,
                    uploadMultiple: true,
                    init: function() {
                        for (let i = 0; i < files.length; i++) {
                            const dropzoneFile = {
                                name: files[i].name,
                                size: files[i].size,
                                file_id: files[i].id,
                                file: files[i].file,
                                url: files[i].url,
                            };
                            this.displayExistingFile(dropzoneFile, files[i].type === 'png' ? files[i].url : replacement);
                        }
                        this.on('maxfilesexceeded', function(file) {
                            this.removeFile(file);
                            $('#fileAmountExceeded').modal('show');
                        });

                        const decorateFile = function(dropzoneFile) {
                            const removeButton = $('' +
                                '<button class="btn btn-danger remove-multi-freelancer-file">' +
                                '<i class="fa fa-trash"></i>' +
                                '</button>',
                            );

                            const extension = (dropzoneFile.filename || '').toLowerCase().split('.').pop();
                            if (extension !== 'png' && extension !== 'jpg' && extension !== 'jpeg') {
                                $(dropzoneFile.previewTemplate).find('.dz-image')
                                    .css('display', 'flex')
                                    .css('justify-content', 'center')
                                    .css('align-items', 'center')
                                    .addClass('bg-success')
                                    .html(
                                        $('<span>')
                                            .css('font-weight', 'bold')
                                            .css('color', 'white')
                                            .text(extension.toUpperCase()),
                                    );
                            }

                            $(dropzoneFile.previewTemplate).append(removeButton);
                            $(removeButton).on('click', function(e) {
                                e.preventDefault();
                                e.stopPropagation();
                                $.ajax({
                                    type: 'DELETE',
                                    url: dropzoneFile.url,
                                    success: function() {
                                        dropzone.removeFile(dropzoneFile);
                                        dropzone.options.maxFiles += 1;
                                    },
                                });
                            });
                            $(dropzoneFile.previewTemplate).on('click', function(e) {
                                e.preventDefault();
                                window.location = dropzoneFile.url;
                            });
                        };

                        this.on('addedfile', function() {
                            $.ajax({
                                type: 'POST',
                                url: baseUrl,
                                success: function() {
                                    location.reload();
                                },
                            });
                        });

                        this.on('success', function(file, response) {
                            file.file_id = response.file_id;
                        });
                        this.on('thumbnail', decorateFile);
                    },
                });
                $(element).addClass('dropzone');
            },
        });
    });
});
