$('#save-contact-data').on('click', function() {
    $('.text-danger').text('');
    $('.contact-data input').each(function() {
        if ($(this).attr('name') !== 'company') {
            if ($(this).attr('required') && $.trim($(this).val()).length === 0) {
                $(this).parent().find('.text-danger').text('Feld darf nicht leer sein!');
                return;
            }
            if (!isEmail($('[name="email"]').val())) {
                $('[name="email"]').parent().find('.text-danger').text('Bitte geben Sie eine gültige E-Mail-Adresse an');
                return;
            }
        }
    });
    const data = {
        company: $('[name="company"]',
        ).val(),
        house_number: $('[name="house_number"]').val(),
        street: $('[name="street"]').val(),
        zip: $('[name="zip"]').val(),
        city: $('[name="city"]').val(),
        phone: $('[name="phone"]').val(),
        email: $('[name="email"]').val(),
        email_billing: $('[name="email_billing"]').val(),
        taxId: $('[name="taxId"]').val(),
        ustId: $('[name="ustId"]').val(),
    };
    $.post('/profil/save-contact-data', data, function(result) {
        if (result.status === 'ok') {
            $('.contact-data .alert-success').show();
            setTimeout(function() {
                $('.contact-data .alert-success').fadeOut('slow');
            }, 4000);
        } else {
            $.each(result.errors, function(idx, val) {
                $('[name="' + idx + '"]').parent().find('.text-danger').text(val);
            });
        }
    });
});

$('.clipboard').on('click', function() {
    const copytext = $(this).closest('.input-group').find('.form-control').val();
    navigator.clipboard.writeText(copytext);
});

$('#save-new-password').on('click', function() {
    $('.text-danger').text('');
    if ($.trim($('#password').val()).length === 0) {
        $('#password').parent().find('.text-danger').text('Passwort darf nicht leer sein!');
        return;
    }
    if ($.trim($('#repeat-password').val()).length === 0) {
        $('#repeat-password').parent().find('.text-danger').text('Bitte wiederholen Sie das Passwort!');
        return;
    }
    const data = {
        password: $('#password').val(),
        password_repeat: $('#repeat-password').val(),
    };
    $.post('/profil/save-new-password', data, function(result) {
        if (result.status === 'ok') {
            $('.new-password .alert-success').show();
            setTimeout(function() {
                $('.new-password .alert-success').fadeOut('slow');
            }, 4000);
            $('#password').val('');
            $('#repeat-password').val('');
        } else {
            $.each(result.errors, function(idx, val) {
                $('#' + idx).parent().find('.text-danger').text(val);
            });
            $('#repeat-password').val('');
        }
    });
});

$('#save-preferences').on('click', function() {
    const data = {
        decimal_format: $('#decimal-format option:selected').val(),
        filetype: $('#filetype option:selected').val(),
        language: $('#language').val(),
    };
    $.post('/profil/save-preferences', data, function(result) {
        if (result.status === 'ok') {
            $('.preferences .alert-success').show();
            setTimeout(function() {
                $('.preferences .alert-success').fadeOut('slow');
            }, 4000);
        }
    });
});

function isEmail(email) {
    const regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
}

const inputSibling = (target) => $(target).parents('.col-12').siblings('.col-12').find('input')[0];
const isEmpty = ({target}) =>
    inputSibling(target).value.length === 0 &&
    $(target).val().length === 0;

const makeRequest = (target, url, type, data, callback) => {
    $(target).attr('disabled', 'disabled');
    $.ajax({
        url: url,
        type: type,
        data: data,
        success: (result) => {
            $(target).removeAttr('disabled');
        },
        done: callback,
    }).done(() => {
    });
};

const addAdditionalInputField = (target) => {
    const parent = target.closest('.card-body');
    const formEmailAddress = "" +
        "<div class=\"row\">" +
        "<div class=\"col-12 col-md-5\"> " +
        "<div class=\"form-group\">" +
        "<input id=\"additional-email-address-alias\" class=\"form-control\" type=\"text\" placeholder=\"Alias\">" +
        "<p class=\"text-danger\"></p> " +
        "</div> " +
        "</div>" +
        "<div class=\"col-12 col-md-6\"> " +
        "<div class=\"form-group\">" +
        "<input id=\"additional-email-address-input\" class=\"form-control\" type=\"email\" placeholder=\"E-Mail-Addresse\">" +
        "<p class=\"text-danger\"></p>" +
        "</div> " +
        "</div> " +
        "<div class=\"col-12 col-md-1\"> <div class=\"form-group\">" +
        "<button class=\"additional-email-address-save btn btn-primary\" type=\"submit\">Speichern</button>" +
        "</div>" +
        "</div>" +
        "</div>";
    const formContent = document.createElement("div").innerHTML = formEmailAddress;
    parent.append(formContent);
};

const inputSelector = (target) => {
    const alias = $(target).parent().parent().parent().find('.additional-email-address-alias').val();
    const email = $(target).parent().parent().parent().find('.additional-email-address-input').val();
    return {
        alias,
        email,
    };
};
[$('.additional-email-address-alias'), $('.additional-email-address-input')].every((element) => {
    element.on('change', (target) => {
        isEmpty(target);
    });
    return true;
});

$('.additional-email-address-save').on('click', ({target}) => {
    const {alias, email} = inputSelector(target);
    const data = {
        'alias': alias,
        'email': email,
    };
    if (email && alias) {
        makeRequest(
            target,
            '/profil/additional-email-address/',
            'POST',
            data,
        );
        addAdditionalInputField(target);
    }
});

$('.additional-email-address-edit').on('click', ({target}) => {
    const {alias, email} = inputSelector(target);
    const id = $(target).attr('data-id');
    const data = {
        'alias': alias,
        'email': email,
        'id': id,
    };
    makeRequest(
        target,
        '/profil/additional-email-address/',
        'POST',
        data);
});

// $('.additional-email-address-input').on('change')

$('.additional-email-address-delete').on('click', ({target}) => {
    const id = $(target).attr('data-id');
    makeRequest(
        target,
        `/profil/additional-email-address/` + $.param({id}),
        'DELETE',
    );
});
