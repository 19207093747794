import {
    datatablesDefaultOptions,
} from './dt';
import {
    language,
} from '../../common/language.js';

const renderRowControls = function(data, type, row) {
    if (row.storno) {
        return '<a class="btn downloadStornoInvoice text-warning" type="button" href="' + row.storno_pdf_url + '"><i class="fa fa-download"></i></button>';
    } else if (row.provision) {
        return '<a class="btn downloadInvoice" type="button" href="' + row.provision_pdf_url + '"><i class="fa fa-download"></i></button>';
    } else {
        return '<a class="btn downloadInvoice" type="button" href="' + row.invoice_pdf_url + '"><i class="fa fa-download"></i></button>';
    }
};

const renderStatusBadge = function(data, type, row) {
    if (language === 'de') {
        if (row.storno) {
            return '<span class="badge badge-warning">Storniert</span>';
        }
        if (data === 'offen') {
            return '<span class="badge badge-danger">Offen</span>';
        } else {
            return '<span class="badge badge-success">Bezahlt</span>';
        }
    } else {
        if (row.storno) {
            return '<span class="badge badge-warning">canceled</span>';
        }
        if (data === 'offen') {
            return '<span class="badge badge-danger">open</span>';
        } else {
            return '<span class="badge badge-success">payed</span>';
        }
    }
};

$('#table-invoices').DataTable($.extend({}, datatablesDefaultOptions, {
    rowReorder: {
        update: true,
    },
    columnDefs: [
        {
            targets: 3,
            render: renderStatusBadge,
        },
        {
            targets: 4,
            render: renderRowControls,
        },
    ],
}));
