import {
    datatablesDefaultOptions,
} from './dt';

$('#table-contacts').DataTable($.extend({}, datatablesDefaultOptions, {
    rowReorder: {
        update: true,
    },
    columnDefs: [
        {
            className: "text-center",
            targets: [4],
        },
    ],
}));
