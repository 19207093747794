import {
    datatablesDefaultOptions,
} from './dt';

$('#table-credits').DataTable($.extend({}, datatablesDefaultOptions, {
    rowReorder: {
        update: true,
    },
    columnDefs: [
    ],
}));
