import moment from 'moment';

const lang = $('html').attr('lang');
//$.fn.dataTable.ext.errMode = undefined;

export const exportAllPagesCSV = function(e, dt, button, config) {
    const self = this;
    const oldStart = dt.settings()[0]._iDisplayStart;

    dt.one('preXhr', function(e, s, data) {
        // Just this once, load all data from the server...
        data.start = 0;
        data.length = dt.page.info().recordsDisplay;

        dt.one('preDraw', function(e, settings) {
            // Call the original action function
            const exporter = $.fn.dataTable.ext.buttons.csv();
            $.fn.dataTable.ext.buttons[exporter].action.call(self, e, dt, button, config);

            dt.one('preXhr', function(e, s, data) {
                // DataTables thinks the first item displayed is index 0, but we're not drawing that.
                // Set the property to what it was before exporting.
                settings._iDisplayStart = oldStart;
                data.start = oldStart;
            });

            // Reload the grid with the original page. Otherwise, API functions like table.cell(this) don't work properly.
            setTimeout(dt.ajax.reload, 0);

            // Prevent rendering of the full data to the DOM
            return false;
        });
    });

    // Requery the server with the new one-time export settings
    dt.ajax.reload();
};

export const exportAllPagesExcel = function(e, dt, button, config) {
    const self = this;
    const oldStart = dt.settings()[0]._iDisplayStart;

    dt.one('preXhr', function(e, s, data) {
        // Just this once, load all data from the server...
        data.start = 0;
        data.length = dt.page.info().recordsDisplay;

        dt.one('preDraw', function(e, settings) {
            // Call the original action function
            const exporter = $.fn.dataTable.ext.buttons.excel();
            $.fn.dataTable.ext.buttons[exporter].action.call(self, e, dt, button, config);

            dt.one('preXhr', function(e, s, data) {
                // DataTables thinks the first item displayed is index 0, but we're not drawing that.
                // Set the property to what it was before exporting.
                settings._iDisplayStart = oldStart;
                data.start = oldStart;
            });

            // Reload the grid with the original page. Otherwise, API functions like table.cell(this) don't work properly.
            setTimeout(dt.ajax.reload, 0);

            // Prevent rendering of the full data to the DOM
            return false;
        });
    });

    // Requery the server with the new one-time export settings
    dt.ajax.reload();
};

export const dataTablesLocales = {
    en: {
        "sEmptyTable": '<div class="alert alert-warning" role="alert">No entries found</div>',
        "sInfo": "Showing _START_ to _END_ of _TOTAL_ entries",
        "sInfoEmpty": "Showing 0 to 0 of 0 entries",
        "sInfoFiltered": "(filtered from _MAX_ total entries)",
        "sInfoPostFix": "",
        "sInfoThousands": ",",
        "sLengthMenu": "Show _MENU_ entries",
        "sLoadingRecords": "Loading...",
        "sProcessing": '<i class="fa fa-spinner fa-spin fa-2x"></i>  Please wait...',
        "sSearch": "Search",
        "sZeroRecords": '<div class="alert alert-warning" role="alert">No matching records found.</div>',
        "oPaginate": {
            "sFirst": "First",
            "sPrevious": "Previous",
            "sNext": "Next",
            "sLast": "Last",
        },
        "oAria": {
            "sSortAscending": ": Order by this column",
            "sSortDescending": ": Reverse order this column",
        },
        "buttons.reload": "<i class=\"fa fa-sync\"></i> Refresh",
    },
    de: {
        "sEmptyTable": '<div class="alert alert-warning" role="alert">Keine Einträge gefunden</div>',
        "sInfo": "_START_ bis _END_ von _TOTAL_ Einträgen",
        "sInfoEmpty": "0 bis 0 von 0 Einträgen",
        "sInfoFiltered": "(gefiltert von _MAX_ Einträgen)",
        "sInfoPostFix": "",
        "sInfoThousands": ".",
        "sLengthMenu": "_MENU_ Einträge anzeigen",
        "sLoadingRecords": "Wird geladen...",
        "sProcessing": '<i class="fa fa-spinner fa-spin fa-2x"></i>  Bitte warten...',
        "sSearch": "Suche",
        "sZeroRecords": '<div class="alert alert-warning" role="alert">Keine Einträge vorhanden.</div>',
        "oPaginate": {
            "sFirst": "Erste",
            "sPrevious": "Zurück",
            "sNext": "Nächste",
            "sLast": "Letzte",
        },
        "oAria": {
            "sSortAscending": ": aktivieren, um Spalte aufsteigend zu sortieren",
            "sSortDescending": ": aktivieren, um Spalte absteigend zu sortieren",
        },
    },
};

export const daterangeLocale = {
    applyLabel: 'Ok',
    cancelLabel: 'Zurücksetzen',
    customRangeLabel: 'Zeitraum angeben',
    format: 'DD.MM.YYYY',
    firstDay: 1,
    fromLabel: 'Von',
    toLabel: 'Bis',
    daysOfWeek: [
        'So',
        'Mo',
        'Di',
        'Mi',
        'Do',
        'Fr',
        'Sa',
    ],
    monthNames: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember',
    ],
};

export const datetimerangeRanges = {
    de: {
        'Heute': [moment(), moment()],
        'Gestern': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Letzten 7 Tage': [moment().subtract(6, 'days'), moment()],
        'Letzten 30 Tage': [moment().subtract(29, 'days'), moment()],
        'Diesen Monat': [moment().startOf('month'), moment().endOf('month')],
        'Letzten Monat': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    },
    en: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 days': [moment().subtract(6, 'days'), moment()],
        'Last 30 days': [moment().subtract(29, 'days'), moment()],
        'This month': [moment().startOf('month'), moment().endOf('month')],
        'Last month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    },
};

$.fn.dataTable.ext.buttons.reload = {
    text: '<i class="fa fa-sync"></i>' + (lang === 'de' ? ' Aktualisieren' : ' Reload'),
    action: function(e, dt) {
        dt.ajax.reload(null, false);
    },
};

$.fn.dataTable.ext.buttons.csvAll = {
    extend: 'csv',
    text: '<i class="fa fa-file-csv"></i> CSV',
    //filename: 'Kundenexport_' + (new Date()).toISOString(),
    action: exportAllPagesCSV,
    exportOptions: {
        columns: ':visible',
    },
    modifier: {
        page: 'all',
        search: 'applied',
        order: 'current',
    },
};

$.fn.dataTable.ext.buttons.excelAll = {
    extend: 'excel',
    text: '<i class="fa fa-file-excel"></i> XLS',
    //filename: 'Kundenexport_' + (new Date()).toISOString(),
    action: exportAllPagesExcel,
    exportOptions: {
        columns: ':visible',
    },
    modifier: {
        page: 'all',
        search: 'applied',
        order: 'current',
    },
};

$.fn.dataTable.ext.buttons.reset = {
    text: function() {
        return '<i class="fa fa-eraser"></i>' + (lang === 'de' ? ' Filter zurücksetzen' : ' Reset filter');
    },
    action: function(e, dt) {
        dt.columns().every(function() {
            const column = this;
            column.search('');
            $('.datetimerange', this.footer())
              .val(column.search());
            $('input:not(.datetimerange), select', this.footer())
              .val(column.search());
            return true;
        });
        dt.search('');
        dt.ajax.reload(null);
    },
};

$.fn.dataTable.Api.register("isColumnSearchable()", function(colSelector) {
    const idx = this.column(colSelector).index();
    return this.settings()[0].aoColumns[idx].bSearchable;
});

export const renderRowIdButton = function(data, type, row) {
    return '<a href="./' + row.DT_RowId + '/" class="d-block text-center">' + data + '</a></div>';
};

export const datatablesDefaultOptions = {
    processing: true,
    serverSide: true,
    language: dataTablesLocales[lang],
    initComplete: function() {
        $(this).closest('.dt-bootstrap4').find('.pagination').addClass('pagination-sm');
        this.api().columns().every(function() {
            const column = this;
            if (this.isColumnSearchable(column)) {
                $('.datetimerange', this.footer())
                  .val(column.search())
                  .daterangepicker({
                      locale: lang,
                      //format: 'DD.MM.YYYY',
                      maxDate: new Date(),
                      autoUpdateInput: false,
                      showDropdowns: true,
                      opens: 'left',
                      ranges: datetimerangeRanges[lang],
                  })
                  .on('apply.daterangepicker', function(ev, picker) {
                      this.value = picker.startDate.format('DD.MM.YYYY') + ' - ' + picker.endDate.format('DD.MM.YYYY');
                      if (column.search() !== this.value) {
                          column.search(this.value).draw();
                      }
                  })
                  .on('cancel.daterangepicker', function() {
                      this.value = '';
                      if (column.search() !== this.value) {
                          column.search(this.value).draw();
                      }
                  });
                $('input, select', this.footer())
                  .val(column.search())
                  .on('keyup change', function() {
                      if (column.search() !== this.value) {
                          column.search(this.value).draw();
                      }
                  });
            }
            return true;
        });
    },
    dom: '<"row mt-2"<"col-md-6"l><"col-md-6"p>><"row"<"col-md-6"i>><"row"<"col-sm-12"B>><"row"<"col-sm-12 col-table"t>><"row"<"col-md-6"li><"col-md-6"p>>r',
    buttons: [
        {
            extend: 'reload',
            className: 'btn-sm',
        },
        {
            extend: 'reset',
            className: 'btn-sm',
        },
    ],
    columnDefs: [
        {
            targets: 0,
            render: renderRowIdButton,
        },
    ],
    stateSave: true,
};

export const initColumnFilter = function() {
    const column = this;
    if (this.isColumnSearchable(column)) {
        $('.datetimerange', this.footer())
            .val(column.search())
            .daterangepicker({
                autoUpdateInput: false,
                showDropdowns: true,
                maxSpan: false,
                timePicker: true,
                timePicker24Hour: true,
                locale: {
                    applyLabel: 'Ok',
                    cancelLabel: 'Zurücksetzen',
                    customRangeLabel: 'Zeitraum angeben',
                    format: 'DD.MM.YYYY HH:mm',
                    firstDay: 1,
                    fromLabel: 'Von',
                    toLabel: 'Bis',
                    daysOfWeek: [
                        'So',
                        'Mo',
                        'Di',
                        'Mi',
                        'Do',
                        'Fr',
                        'Sa',
                    ],
                    monthNames: [
                        'Januar',
                        'Februar',
                        'März',
                        'April',
                        'Mai',
                        'Juni',
                        'Juli',
                        'August',
                        'September',
                        'Oktober',
                        'November',
                        'Dezember',
                    ],
                },
                opens: 'left',
                ranges: datetimerangeRanges[lang],
            })
            .on('apply.daterangepicker', function(ev, picker) {
                this.value = picker.startDate.format('DD.MM.YYYY') + ' - ' + picker.endDate.format('DD.MM.YYYY');
                if (column.search() !== this.value) {
                    column.search(this.value).draw();
                }
            })
            .on('cancel.daterangepicker', function() {
                this.value = '';
                if (column.search() !== this.value) {
                    column.search(this.value).draw();
                }
            });

        $('input:not(.datetimerange), select', this.footer())
          .val(column.search())
          .on('keyup change', function() {
              if (column.search() !== this.value) {
                  column.search(this.value).draw();
              }
          });
    }
};
