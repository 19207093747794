// https://stackoverflow.com/a/65592593
export function encodeHTMLEntities(text) {
    return $("<textarea/>").text(text).html();
}

export function decodeHTMLEntities(text) {
    return $("<textarea/>").html(text).text();
}

window.encodeHTMLEntities = encodeHTMLEntities;
window.decodeHTMLEntities = decodeHTMLEntities;
