function mountMiniLists() {
    document.querySelectorAll(".mini-list").forEach(function(miniList) {
        mountMiniList(miniList);
    });
}

const FadeOut = {
    onbeforeremove: function(vnode) {
        vnode.dom.classList.add('exit');
        return new Promise(function(resolve) {
            vnode.dom.addEventListener('animationend', resolve);
        });
    },
    view: function(vnode) {
        return vnode.attrs.render && vnode.attrs.render();
    },
};

function createMiniList(list) {
    return {
        renderAlerts: function() {
            return [
                list.error && m(FadeOut, {
                    render: function() {
                        return m('.alert.alert-danger', list.error);
                    },
                }),
                list.success && m(FadeOut, {
                    render: function() {
                        return m('.alert.alert-success', list.success);
                    },
                }),
            ];
        },
        renderItemAsBadge: function(item) {
            return m('.badge.badge-pill.m-2.text-lg', {
                class:
                    (item.id === list.currentItem.id ? 'badge-primary' : 'badge-secondary') +
                    (item[':highlighted'] ? ' font-weight-bold' : ''),
                title: list.config.titleField ? item[list.config.titleField] : undefined,
                style: {
                    cursor: 'default',
                },
                onclick: list.config.canUpdate && function(ev) {
                    ev.stopPropagation(); list.currentItem = item;
                },
            }, [
                list.config.labelField ? item[list.config.labelField] : (item.label || item.name || item.id),
                list.config.canRemove && m('span.ml-2', {
                    title: list.config.titleRemove || 'Löschen',
                    style: {
                        cursor: 'pointer',
                    },
                    onclick: function(ev) {
                        ev.stopPropagation();
                        if (!list.config.deleteConfirmation ||
                            window.confirm(list.config.deleteConfirmation)
                        ) {
                            list.removeItem(item);
                        }
                    },
                }, m('i.fa.fa-lg.fa-times')),
            ]);
        },

        renderCell: function(item, column) {
            const value = item[column.field];
            if (value === null) {
                return '';
            } else if (column.type === 'select') {
                const option = column.options.find(function(option) { return option.id === value });
                return option && option.name;
            } else if (column.type === 'number') {
                return (!column.showZeros && value === 0) ? '' : new Intl.NumberFormat('de-DE',
                    column.currency ? {style: 'currency', currency: column.currency} : {},
                ).format(value);
            } else if (column.type === 'checkbox') {
                return value ? m.trust('&check;') : undefined;
            }
            return value;
        },

        renderItemAsRow: function(item) {
            const that = this;
            return m('tr', {
                class: (
                    (item.id === list.currentItem.id ? 'bg-primary text-white' : '') +
                    (item[':highlighted'] ? ' font-weight-bold' : '')
                ).trim(),
                style: {
                    cursor: 'default',
                },
                onclick: list.config.canUpdate && function(ev) {
                    ev.stopPropagation();
                    list.select(item);
                },
            }, [
                list.config.labelField && m('td', item[list.config.labelField]),
                list.getColumns(true).map(function(column) {
                    return m('td.pl-3.pr-3', that.getColumnStyle(column), that.renderCell(item, column));
                }),
                list.config.canRemove ? m('td', {
                    title: list.config.titleRemove || 'Löschen',
                    style: {
                        cursor: 'pointer', textAlign: 'center',
                    },
                    onclick: function(ev) {
                        ev.stopPropagation();
                        if (!list.config.deleteConfirmation ||
                            window.confirm(list.config.deleteConfirmation)
                        ) {
                            list.removeItem(item);
                        }
                    },
                }, m('i.fa.fa-lg.fa-times.text-danger')) : m('td', ''),
            ]);
        },

        renderTableHeader: function() {
            const that = this;
            return m('thead', m('tr', [
                list.config.labelField && m('th', ''),
                list.getColumns(true).map(function(column) {
                    return m('th.pl-3.pr-3', that.getColumnStyle(column), column.label);
                }),
                m('th', {style: {width: '200px'}}, ''),
            ]));
        },

        renderTableFoot: function() {
            const that = this;
            return list.getCurrentView() !== 'none' && m('tfoot', m('tr', [
                list.config.labelField && m('td', ''),
                list.getColumns().map(function(column) {
                    return m('td', that.getColumnStyle(column), m('.w-100', {
                        style: column.type === 'number' ? {
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                        } : undefined,
                    }, that.renderControl(column)));
                }),
                m('td', m('.w-100', {style: {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                }}, this.renderSubmit())),
            ]));
        },

        getColumnStyle: function(column) {
            return {
                style: {
                    textAlign: column.type === 'number' ?
                        'right' :
                        (column.type === 'checkbox' ? 'center' : 'left'),
                },
            };
        },

        renderTable: function() {
            const that = this;
            return m('table.table.table-striped.table-sm.table-hover', [
                this.renderTableHeader(),
                m('tbody', list.items.map(function(item) {
                    return that.renderItemAsRow(item.id === list.currentItem.id ? list.currentItem : item);
                })),
                this.renderTableFoot(),
            ]);
        },

        renderList: function() {
            return list.config.tabular ? this.renderTable() : list.items.map(this.renderItemAsBadge);
        },

        renderControl: function(column) {
            const value = list.currentItem[column.field];
            if (column.type === 'select') {
                return m('select.form-control', {
                    onchange: function(ev) {
                        list.currentItem[column.field] =
                            ev.target.value === undefined ? undefined : Number(ev.target.value);
                    },
                    onclick: function(ev) { ev.stopPropagation() },
                    disabled: list.currentItem[column.field + ':disabled'],
                }, list.getOptions(column).map(function(option) {
                    return m('option', {
                        key: option.id,
                        value: option.id,
                        selected: value === option.id,
                    }, option.name);
                }));
            }
            return m('form', {
                autocomplete: 'off',
                onsubmit: function() {
                    return false;
                },
            }, m('input', {
                autocomplete: column.type === 'password' ? 'new-password' : 'off',
                class: column.type === 'checkbox' ? 'form-custom-input' : 'form-control',
                type: column.type || 'text',
                placeholder: (list.config.tabular || column.type === 'checkbox') ? undefined : (column.label || ''),
                value: column.type === 'checkbox' ? undefined : value,
                oncreate: column.type === 'checkbox' && function(vnode) { vnode.dom.checked = value },
                onupdate: column.type === 'checkbox' && function(vnode) { vnode.dom.checked = value },
                onkeyup: function(ev) {
                    if (ev.key === 'Enter') {
                        list.sendItem();
                    }
                },
                oninput: function(ev) {
                    list.currentItem[column.field] = column.type === 'checkbox' ?
                        ev.target.checked :
                        ev.target.value;
                },
                onclick: function(ev) { ev.stopPropagation() },
                disabled: list.currentItem[column.field + ':disabled'],
                required: column.required,
                style: {
                    width: column.type === 'number' ? '100px' : undefined,
                    textAlign: column.type === 'number' ? 'right' : undefined,
                },
            }));
        },

        renderSubmit: function() {
            return m('button.btn.btn-outline-secondary',
                {
                    disabled: !list.itemIsValid(),
                    onclick: function(ev) {
                        ev.preventDefault();
                        ev.stopPropagation();
                        if (!list.config.updateConfirmation ||
                            list.getCurrentView() !== 'update' ||
                            window.confirm(list.config.updateConfirmation)
                        ) {
                            list.sendItem();
                        }
                    },
                },
                list.getCurrentView() === 'create' ?
                    list.config.createLabel || 'Hinzufügen' :
                    list.config.updateLabel || 'Speichern',
            );
        },

        view: function() {
            const asCol = list.element.dataset.display === 'col';
            const viewMode = list.getCurrentView();
            const that = this;
            return m((asCol ? '.col' : '.card'), [
                !asCol && list.config.title && m('.card-header', list.config.title),
                m(
                    (asCol ? '' : '.card-body') + (list.config.tabular ? '' : '.p-1'),
                    {onclick: function(ev) { viewMode !== 'create' && list.deselect() }},
                    [
                        !list.items.length && m('.w-100.m-2.text-center', list.config.emptyLabel || '...'),
                        this.renderList(),
                        this.renderAlerts(),
                    ],
                ),
                (!list.config.tabular && viewMode !== 'none') && m((asCol ? 'div' : '.card-footer'), m('.input-group', [
                    list.getColumns().map(function(column) {
                        return that.renderControl(column);
                    }),
                    m('.input-group-append', this.renderSubmit()),
                ])),
            ]);
        },
    };
}

function MiniListModel(element) {
    this.element = element;
    this.config = {};
    this.items = [];
    this.currentItem = {};
    this.load();
}

MiniListModel.prototype.readResponse = function(response) {
    this.success = response.success;
    this.error = response.error;
    this.config = response.config || this.config;
    this.items = Array.isArray(response.items) ? response.items : this.items;
    const that = this;
    if (this.success) {
        setTimeout((function() { delete this.success; m.redraw() }).bind(this), 1000);
    }
    if (this.error) {
        const updatedItem = this.items.find(function(item) { return item.id === that.currentItem.id });
        if (updatedItem) {
            this.select(updatedItem);
        }
        setTimeout((function() {
            delete this.error;
            m.redraw();
        }).bind(this), 1000);
    } else {
        this.deselect();
    }
};

MiniListModel.prototype.load = function() {
    m.request({
        method: 'GET',
        url: this.element.dataset.url,
    }).then((function(response) { this.readResponse(response) }).bind(this));
};

MiniListModel.prototype.sendItem = function() {
    if (this.itemIsValid()) {
        m.request({
            method: 'POST',
            url: this.element.dataset.url + (this.currentItem.id ? ('/' + this.currentItem.id) : ''),
            body: this.currentItem,
        }).then((function(response) { this.readResponse(response) }).bind(this));
    }
};

MiniListModel.prototype.removeItem = function(item) {
    m.request({
        method: 'DELETE',
        url: this.element.dataset.url + '/' + item.id,
    }).then((function(response) { this.readResponse(response) }).bind(this));
    this.deselect();
};

MiniListModel.prototype.itemIsValid = function() {
    return (this.config.columns || []).reduce((function(value, column) {
        return value && (!column.required || String(this.currentItem[column.field]).trim().length > 0);
    }).bind(this), true);
};

MiniListModel.prototype.getCurrentView = function() {
    if (!this.currentItem.id && this.config.canCreate) {
        return 'create';
    }
    if (this.currentItem.id && this.config.canUpdate) {
        return 'update';
    }
    return 'none';
};

MiniListModel.prototype.getColumns = function(all) {
    if (all) {
        return this.config.columns || [];
    }
    const view = this.getCurrentView();
    return (this.config.columns || []).filter(function(column) {
        return column.view === undefined || column.view === view;
    });
};

MiniListModel.prototype.getOptions = function(column) {
    const that = this;
    return (column.options || []).filter(function(option) {
        return !column.exclusive || !that.items.find(function(item) {
            return item[column.field] === option.id && item.id !== that.currentItem.id;
        });
    });
};

MiniListModel.prototype.select = function(item) {
    this.currentItem = Object.assign({}, item);
};

MiniListModel.prototype.deselect = function() {
    const that = this;
    this.currentItem = {};
    this.getColumns().forEach(function(column) {
        if (column.default !== undefined) {
            that.currentItem[column.field] = column.default;
        } else if (column.type === 'select') {
            const options = that.getOptions(column);
            if (options.length > 0) {
                that.currentItem[column.field] = options[0].id;
            }
        }
    });
};

function mountMiniList(element) {
    if (element.dataset.mounted !== 'true') {
        element.dataset.mounted = 'true';
        m.mount(element, createMiniList(new MiniListModel(element)));
    }
}

window.mountMiniLists = mountMiniLists;
